import React, { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { useAtom } from 'jotai';
import useUser from '@hooks/useUser';
import usePosts from '@hooks/usePosts';
import { navigate } from 'gatsby';
import { SplashScreen, HomePosts } from '@components';
import { App } from '@layouts';
import { appModal, appVersion } from 'utils/store';
import supabase from 'utils/supabase';

const Index = () => {
  const [, setModal] = useAtom(appModal);
  const [, setVersion] = useAtom(appVersion);
  const {
    loading,
    session,
    updateUserTimeZone: { mutate: updateUserTimeZone },
  } = useUser();
  const {
    fetchNewsFeed: { mutate: fetchNewsFeedPosts },
  } = usePosts();

  useEffect(() => {
    CapacitorApp.addListener('appUrlOpen', async (event) => {
      if (event.url.indexOf('https') !== -1) {
        const path = event.url.split('.ai').pop(); // .app for Netlify domain an .ai for live domain
        if (path) {
          navigate(path);
        }
      } else if (
        event.url.indexOf('app.thenewsroom.ai://login-callback/') !== -1
      ) {
        const authURL = event.url.split(
          'app.thenewsroom.ai://login-callback/',
        )[1];
        const authParams = new URLSearchParams(authURL);
        const refreshToken = authParams.get('refresh_token');
        await supabase.auth.setSession(refreshToken);
        navigate('/');
      }
    });
    if (
      Capacitor.getPlatform() === 'web' &&
      process.env.NODE_ENV !== 'development'
    ) {
      setModal(true);
    }
    const updateApp = async () => {
      CapacitorUpdater.notifyAppReady();
      let build = '';
      try {
        ({ build } = await CapacitorApp.getInfo());
      } catch (error) {
        build = 'v0';
      }
      const {
        bundle: { version },
        native,
      } = await CapacitorUpdater.current();
      const appVersion = version === 'builtin' ? native : version;
      const versionString = `${appVersion} - ${build}`;
      if (appVersion && versionString) {
        setVersion(`${versionString}`);
      }
    };
    updateUserTimeZone();
    updateApp();
  }, []);

  if (loading) {
    return <SplashScreen />;
  }

  const onRefresh = async (resolve) => {
    await fetchNewsFeedPosts();
    return resolve();
  };

  if (!session) {
    navigate('/sign-in');
    return null;
  }

  return (
    <App onRefresh={onRefresh}>
      <HomePosts />
    </App>
  );
};

export default Index;
